import React from 'react';

export default class OptionsNearby extends React.Component {

	state = {
		venues: []
	}

	componentDidMount(){
		var _this=this;
      $.post( options.restURL + "/plantswithindistance/", {plantId: this.props.venue, distanceInMeters: "10000" }, function( a ) {
        a = a.plants_data;

        var r = [];
        var i=0;
        for (var n in a){
          a[n].id = n,
          r[n] = a[n];
          i++;

						if ((i ===4))
						{
							break;
						}

					}

        _this.setState({venues: r});
      });
	}

	renderVenue(venue, i){
		var url = options.venuePageUrl + "/" + venue.nameForUrl + "_" + venue.plantId;

		let image;

		if (venue.images) {
        image = venue.images.shift();
    }

		if(i == 2) {

			setTimeout(function() {
				var maxSlides,
					    width = $(window).width();

					if (width < 430) {
					    maxSlides = 1;
					} else {
					    maxSlides = 3;
					}

				let slider = $(".nearby_wrapper").bxSlider({
						auto: true,
						stopAuto: false,
						speed: 500,
						slideWidth: 300,
						minSlides: 1,
						maxSlides: maxSlides,
						moveSlides: 1,
						slideMargin: 5,
						pager: !1,
						controls: !0,
						randomStart: !1,
						preloadImages: "all",
						adaptiveHeight: true,
						responsive: !0,
						touchEnabled: true,
						nextText: "",
						prevText: "",
						onSliderAfter: function(e) {
						slider.startAuto();
						slider.stopAuto();
						}
				});
			}, 50)

		}

		return (
			<div key={`venue_${venue.plantId}`} className="col-md-3 col-md-offset-0 col-xs-3 col-xs-offset-0  bxslider-nearby">
				<div className="nearby_item" style={{width: `250px`}}>
					<div className="appearance">
						<img src={`https://${image.image}`} width={`100%`} height={`200px`} alt="" />
					</div>
					<div className="description">
						<p className="name">{ venue.name }</p>
						<p>{ cutWords(venue.factsAboutPlant) }</p>
					</div>
					<div className="nearby_look">
						<a href={ url }>
							{ lang.viewRooms }
						</a>
					</div>
				</div>
			</div>

		);
	}

  render () {
		if(this.state.venues.length == 0){
			return (<div>Loading...</div>);
		}

    return (
			<div className="venue_nearby v_info venue_slider" id="options_nearby">
				<div className="venue_nearby v_info">
					<div className="heading">
						<h2 className="text-center" >{ lang.venueOptionsNearby }</h2>
					</div>
					<div className="row nearby_wrapper">
						{ this.state.venues.map(this.renderVenue) }
					</div>
				</div>
			</div>
    );
  }
}
