import React from 'react';
import ReactDOM from 'react-dom';
import CreateSearch from '../helpers/createSearch.js';

import RootComponent from './root.jsx';

const mainblock = document.querySelector('main');

ReactDOM.render(<RootComponent />, mainblock);

CreateSearch();
