import React from 'react';

import createSearch from '../../helpers/createSearch.js';

export default class TimePicker extends React.Component {


  render () {
    return (
      <div className="col-sm-10 col-sm-offset-0 col-md-10 col-md-offset-0 col-xs-10 col-xs-offset-0 date">
				<div className="styled_time">
					<select>
						<option value="" defaultValue>Tid</option>
						<option value="pre">Förmiddag</option>
						<option value="post">Eftermiddag</option>
						<option value="whole">Heldag</option>
					</select>
				</div>
      </div>
    );
  }
}
