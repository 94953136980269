import React from 'react';

export default class DescriptionData extends React.Component {

	componentDidMount(){
		var collapsableTextOpen = false;
		$(".read-more-button").click(function () {
			var collapsableText = $("#description_data").find("p.text-info");
			var toggleButton = $(this);

			if(!collapsableTextOpen) {
					collapsableText.css("-webkit-line-clamp", "initial");
					toggleButton.text("Dölj information")
			} else {
				collapsableText.css("-webkit-line-clamp", "3");
				toggleButton.text("Mer information")
			}

			collapsableTextOpen = !collapsableTextOpen;
	});
	}

  render () {
    return (
			<div className="venue_descr v_info">
				<div className="heading">
					<h2 className="name">Om Anläggning 2</h2>
				</div>
				<div id="description_data">
					<p className="text-info">{ venueData.plantFacts }</p>
				</div>
				<div className="read-more-button-wrapper">
					<span className="read-more-button">Mer information</span>
				</div>
			</div>
    );
  }
}
