import React from 'react';
import './ExpandRoom.css';

export default class ExpandRoom extends React.Component {

	componentDidMount(){

		let closeExpandBtns = $('.closeExpand');

		closeExpandBtns.each(function(){
			$(this).on("click", function(){
				let closeExpandBtnId = $(this).attr("value");
				$(".expandRoom-"+closeExpandBtnId).addClass("hidden-div");
    	});
		});
	}

	render() {
		return (
			<div className={`expandRoom-${this.props.id} expandRoomStyle hidden-div`}>
				<div className="expandAppearance" style={{backgroundImage: "url('" + this.props.image + "')"}}>
					ef
				</div>
				<p>{this.props.id}</p>
				<p>{this.props.name}</p>
				<button className="closeExpand" name="info" type="submit" value={this.props.id}>Close</button>
			</div>
		)
	}
}
