import React from 'react'
import ExpandRoom from '../ExpandRoom'
import './SearchResults.css';


export default class SearchResults extends React.Component {

	componentDidMount(){
		$("#options_nearby").removeClass("hidden-div");
    $(".loading").fadeOut("slow");
		let expandBtns = $('.expandRoomBtn');

		expandBtns.each(function(){
			$(this).on("click", function(){
				let expandBtnId = $(this).attr("value");
				let expandedDiv = $(".expandRoom-"+expandBtnId);
				expandedDiv.removeClass("hidden-div");
    	});
		});
	}

	bookingLinkHandle(event){
		event.preventDefault();
	}

	calculatePriceType(e, t, a){
		e = e.split(":"),
    t = t.split(":"),
    e = e[0],
    t = t[0];
    var r;
    parseInt(t) - parseInt(e);
    return r = 10 >= e && t >= 14 ? "fullDayPrice" : e > 12 ? "afterNoonPrice" : "preNoonPrice"
	}

	plantToArray(e){
		var t = [];
        for (var a in e)
            t[t.length] = e[a];
        return t
	}

	bookingHandleClick(e){
		e.preventDefault();
    var t = $("[name=persons]").val(),
        a = $("[name=date]").val();

    if(t && a) {
      document.location.href = e.currentTarget.href
    } else {
      !a ? $(".venue_filters [name=date]").addClass("error") : $(".venue_filters [name=date]").removeClass("error");
      !t ? $(".venue_filters [name=persons]").addClass("error") : $(".venue_filters [name=persons]").removeClass("error");

      !a || !t ? $(".venue .venue_filters .ok").show() : $(".venue .venue_filters .ok").hide();

      if(!t) $("[name=persons]").focus();
      else if(!a) $("[name=date]").focus();
    }
	}

	renderNoRooms(){
		return (
			<div id="venue_rooms">
				<div className="venue_rooms v_info">
					<div className="heading">
						<h2 className="name">{ lang.venueAvailableRooms }</h2>
					</div>
					<div className="row">
						<div className="alert alert-danger">
							{ lang.venueNoRooms }
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderRoom(e){

		let t = $("[name=persons]").val(),
        dateVal = $("[name=date]").val();

		let a = {
        venue: getParams.venue
    };

		var strFrom = lang.searchFrom.toLowerCase(),
		pre=false,
		aft=false,
		full=false,
		preUrl="",
		aftUrl="",
		fullUrl="",
		generalClass="",
		roomClass="",
		preClass="",
		aftClass="",
		fullClass="",
		preBookNow=lang.bookNow,
		aftBookNow=lang.bookNow,
		fullBookNow=lang.bookNow,
		preText=!dateVal?lang.venuePreNoon+" "+strFrom:lang.venuePreNoon,
		aftText=!dateVal?lang.venueAfterNoon+" "+strFrom:lang.venueAfterNoon,
		fullText=!dateVal?lang.venueFullDay+" "+strFrom:lang.venueFullDay,
		prePrice=Math.round(e.preNoonPrice) + " kr",
		aftPrice=Math.round(e.afterNoonPrice) + " kr",
		fullPrice=Math.round(e.fullDayPrice) + " kr",
		fullStart=e.preNoonAvailabilityHourStart,
		fullEnd=e.preNoonAvailabilityHourEnd,
		fullPriceTmp="",
		preTime=e.preNoonAvailabilityHourStart.slice(0, -3)+"-"+e.preNoonAvailabilityHourEnd.slice(0, -3),
		aftTime=e.afterNoonAvailabilityHourStart.slice(0, -3)+"-"+e.afterNoonAvailabilityHourEnd.slice(0, -3),
		fullTime=e.preNoonAvailabilityHourStart.slice(0, -3)+"-"+e.afterNoonAvailabilityHourEnd.slice(0, -3);


		if(dateVal){
			preClass="disabled",
			aftClass="disabled",
			fullClass="disabled";
		}

		if($("[name=persons]").val()>e.maxSeats){
			roomClass="hidden";
		}


		if(this.props.preNoon.conferenceRoomAvailability){

			this.props.preNoon.conferenceRoomAvailability.map(function(r) {
				if(r.conferenceRoom==e.id){

					pre=r.id;
					a.from = r.hoursAvailableFrom.slice(0, -3);
					a.to = r.hoursAvailableTo.slice(0, -3);
					a.id = r.block;
					a.price = Math.round(r.preNoonPrice);
					a.room = r.conferenceRoom;
					a.booking = r.id;
					a.date = r.start;
					a.persons = $("[name=persons]").val();

					var url = "";
					for (var v in a) url += "&" + v + "=" + a[v];

					preUrl= options.bookingPageUrl + "?" + url.substring(1);
					preClass="";

					prePrice=a.price + " kr"

					fullPriceTmp= Math.round(r.fullDayPrice);
					fullStart=a.from;
					full=r.block;

				}
			})
		}

		// Afternoon
		if(this.props.afterNoon.conferenceRoomAvailability){
			this.props.afterNoon.conferenceRoomAvailability.map(function(r) {
				if(r.conferenceRoom==e.id){
					aft=r.id;

					a.from = r.hoursAvailableFrom.slice(0, -3);
					a.to = r.hoursAvailableTo.slice(0, -3);
					a.id = r.block;
					a.price = Math.round(r.afterNoonPrice);
					a.room = r.conferenceRoom;
					a.booking = r.id;
					a.date = r.start;
					a.persons = $("[name=persons]").val();

					var url = "";
					for (var v in a) url += "&" + v + "=" + a[v];

					aftUrl= options.bookingPageUrl + "?" + url.substring(1);
					aftClass="";

					aftPrice=a.price + " kr"


					fullEnd=a.to;

				}
			})
		}


		// Full day
		if(pre && aft){
			a.from = fullStart;
			a.to = fullEnd;
			a.id = full;
			a.price = fullPriceTmp;
			a.booking = pre+","+aft;
			a.persons = $("[name=persons]").val();

			var url = "";
			for (var v in a) url += "&" + v + "=" + a[v];

			fullUrl= options.bookingPageUrl + "?" + url.substring(1);
			fullClass="";
			fullPrice= a.price + " kr";

			fullBookNow=lang.bookNow;

		}

		if(pre || aft) roomClass="", generalClass="hidden-div";

		aftBookNow= dateVal && !aft ? lang.venueBooked:lang.bookNow;
		preBookNow= dateVal && !pre ? lang.venueBooked:lang.bookNow;
		fullBookNow= dateVal && !aft && !pre ? lang.venueBooked:lang.bookNow;

		if($("[name=persons]").val()>e.maxSeats || (dateVal && (!pre && !aft))){
			roomClass="hidden";
		}

		var l = e.imgUrl?e.imgUrl:"assets/images/noimg.gif";

		let i = [];

		for (let r in e.defaultSeating)
		i.push(e.defaultSeating[r].name);

		var u = e.description ? e.description : e.conferenceRoomDescription;

		var genText = lang.price + " " + Math.round(e.fullDayPrice) + " kr";

		var roomId = e.conferenceRoomId ? e.conferenceRoomId : e.id;

		return (
			<div key={`room_${roomId}`} className={`col-xs-12${roomClass}`}>
				<div className="row venue_room">
					<div className="col-md-6 col-xs-12 RoomAppearance" style={{backgroundImage: "url('" + l + "')"}}>
					</div>
					<div className="col-md-6 col-xs-12 core_info">
						<div className="row description">
							<div className="RoomOverview col-md-9 col-xs-9">
								<h2 className="name">
									{ e.name }
								</h2>
							</div>
							<div className="data_persons col-md-3 col-xs-3">
								<div className="data_val">
									<img className="equipImage" src={"/assets/images/icon_antal.png"} />
									{ e.maxSeats }
								</div>
							</div>
							<div className="RoomText col-md-12 col-xs-12">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut mauris justo, cursus nec augue sed, dictum vulputate leo. Phasellus ac mollis tortor, ac molestie nunc.
								</p>
							</div>
						</div>
						<div className="row">
							<div className="RoomLeft col-md-9 col-xs-9">
								<div className="room_details">
									<div className="room_data">
										<div className="room_equipment col-md-12">
											<div className="data_head">
												Utrustning
											</div>
											<div className="data_val">

												<img className="equipImage" src={"/assets/images/icon_facil01_wifi.png"} alt="No img" title="Wifi" />
												<img className="equipImage" src={"/assets/images/icon_facil02_whiteboard.png"} alt="No img" title="Whiteboard" />
												<img className="equipImage" src={"/assets/images/icon_facil03_projector.png"} alt="No img" title="Projector" />
												<img className="equipImage" src={"/assets/images/icon_facil04_television.png"} alt="No img" title="Television"/>
												<img className="equipImage" src={"/assets/images/icon_facil05_interactiveTV.png"} alt="No img" title="Interactive Tv" />
												<img className="equipImage" src={"/assets/images/icon_facil08_microphone.png"} alt="No img" title="Microphone"/>
											</div>
											<div className="data_head">
												Möjliga tillval
											</div>
											<div className="data_val">
												<img className="equipImage" src={"/assets/images/icon_facil09_pen&paper.png"} alt="No img" title="Pen and paper" />
												<img className="equipImage" src={"/assets/images/icon_facil11_videoconference.png"} alt="No img" title="Video conferance" />
												<img className="equipImage" src={"/assets/images/icon_facil12_flipboard.png"} alt="No img" title="Flip board" />
											</div>
											<div className="data_head">
												 { lang.venueSeat }
											</div>
											<div className="data_val">
												<img className="image" src={"/assets/images/icon_seat01_conference.png"} alt="No img" title="Conferance" />
												<img className="image" src={"/assets/images/icon_seat02_cinema.png"} alt="No img" title="Cinema"/>
												<img className="image" src={"/assets/images/icon_seat06_islands.png"} alt="No img" title=" Islands"/>
												<img className="image" src={"/assets/images/icon_seat07_mingle.png"} alt="No img" title="Mingle"/>
											</div>
										</div>
									</div>
								</div>
								{/*Component below is for an expanded view of the room cards*/}
								{/*<button className="expandRoomBtn" name="info" type="submit" value={roomId}>Expand</button>*/}
								{/*<ExpandRoom id={roomId} name={e.name} image={l} />*/}
							</div>
							<div className="RoomRight col-md-3 col-xs-3">
								<div className="room_prices">
									<div className={`vroom_bookslot ${fullClass}`}>
										<div className="bookslot_description">
											<div className="description_group">
												<div className="description_text col-md-12 col-sm-12 col-xs-12">
													Från
												</div>
												<div className="description_price col-md-12 col-sm-12 col-xs-12">
													{ prePrice }
												</div>
											</div>
											<div className="bookslot_time">
												Välj Tid
											</div>
											<div className="book_btn col-md-12">
													<a href={ fullUrl } onClick={ this.bookingHandleClick }>
														{ fullBookNow }
													</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
	//<div className="pull-right">{ lang.exVAT }</div>
//<div dangerouslySetInnerHTML={{__html: lang.addonsNext}}></div>
  render () {

		let rooms = [];
		let t = $("[name=persons]").val(),
        dateVal = $("[name=date]").val();
    rooms = this.plantToArray(venueRooms);
    //console.log(this.props.preNoon);

		if ((!this.props.preNoon.conferenceRoomAvailability && !this.props.afterNoon.conferenceRoomAvailability && dateVal) || !rooms.length){
			return this.renderNoRooms();
		}

    return (
				<div className="venue_rooms v_info">
					<div className="heading">
						<h2 style={{padding: `6px`}} className="name">{ lang.venueAvailableRooms }</h2>
						<p style={{padding: `6px`}}>Priserna är exklusive moms.</p>
					</div>
					<div className="row">
						{ rooms.map(this.renderRoom.bind(this)) }
					</div>
				</div>
    );
  }
}
