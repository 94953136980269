import React from 'react'

import MainSlider from '../MainSlider';
import VenueFilters from '../VenueFilters';
import DescriptionData from '../DescriptionData';
import OptionsNearby from '../OptionsNearby';
import VenueMap from '../VenueMap';

export default class Venue extends React.Component {
  render () {
    return (
      <div className="venue">
        <MainSlider />
				<div className="container">
					<div className="row">
						<div className="col-sm-4 col-sm-offset-0 col-md-4 col-md-offset-0 col-xs-12 col-xs-offset-0 venue_filters_wrap" >
							<VenueFilters />
							<div className=" read-more-mini">
								<DescriptionData />
								</div>
								<VenueMap />
						</div>
						<div className="col-sm-8 col-sm-offset-0 col-md-8 col-md-offset-0 col-xs-12 col-xs-offset-0 venue_info rooms">
							<div id="venue_rooms">
							</div>
							</div>

					</div>
					<div className="row">
						<div className="col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0 col-xs-12 col-xs-offset-0 venue_nearby">
								<OptionsNearby
									venue={ 2 }
									/>
							</div>
					</div>
				</div>
      </div>
    );
  }
}
